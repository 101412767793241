<template>
  <div class="page-container" v-loading="isLoading">
    <div class="breadcrumb-container">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                                       <el-breadcrumb-item><a href="/">文章列表</a></el-breadcrumb-item>
                                       <el-breadcrumb-item>文章详情</el-breadcrumb-item> -->
        <el-breadcrumb-item v-for="(v, i) in activeMenu" :key="i">{{
          v.name
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="content-header pb10" flex="main:justify cross:center">
        <div></div>
        <div class="search-wrap">
          <el-form
            :inline="true"
            :model="search"
            class="demo-form-inline"
            ref="searchForm"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="位置名称" prop="name">
              <el-input
                v-model="search.name"
                placeholder="请输入位置名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="search.status"
                style="width: 120px"
                placeholder="请选择"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="空闲" value="0"></el-option>
                <el-option label="有人" value="1"></el-option>
                <el-option label="待回收" value="2"></el-option>
                <el-option label="解除认证中" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属账号" prop="account_name">
              <el-input
                v-model="search.account_name"
                placeholder="请输入账号名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属游戏" prop="game_name">
              <el-input
                v-model="search.game_name"
                placeholder="请输入游戏名称"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="resetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="content-body">
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            :max-height="tableMaxHeight"
          >
            <el-table-column
              label="序号"
              type="index"
              :index="mGenTableIndex"
              width="50"
            >
            </el-table-column>
            <el-table-column prop="name" label="位置名称"></el-table-column>
            <el-table-column
              prop="account_name"
              label="所属账号"
            ></el-table-column>
            <el-table-column
              prop="game_name"
              label="所属游戏"
            ></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status == 0" type="primary"
                  >空闲</el-tag
                >
                <el-popover
                  v-if="scope.row.status == 1"
                  placement="top-start"
                  trigger="hover"
                >
                  <div>
                    <div>
                      <span>淘宝订单号：</span
                      ><span>{{ scope.row.order_sn }}</span>
                    </div>
                    <div>
                      <span>订单截止日期：</span
                      ><span>{{
                        $utils.getDate(scope.row.order_end_time * 1000)
                      }}</span>
                    </div>
                  </div>
                  <span slot="reference">
                    <el-tag type="warning" class="app-mr10">有人</el-tag>
                    <el-tag
                      type="danger"
                      v-if="isOrderExpire(scope.row.order_end_time)"
                      >已超时</el-tag
                    >
                  </span>
                </el-popover>
                <el-tag v-if="scope.row.status == 2" type="danger"
                  >待回收</el-tag
                >
                <el-popover
                  v-if="scope.row.status == 3"
                  placement="top-start"
                  trigger="hover"
                >
                  <div>
                    <div>
                      <span>解除认证截止日期：</span
                      ><span>{{
                        $utils.getDate(scope.row.auth_end_time * 1000)
                      }}</span>
                    </div>
                  </div>
                  <el-tag slot="reference" type="info">解除认证中</el-tag>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="note" label="备注"></el-table-column>
            <el-table-column
              label="操作"
              width="250"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <div>
                  <div>
                    <el-link
                    class="app-mr10"
                    type="primary"
                    @click="editStatus(scope.row)"
                    >设置状态</el-link
                  >
                  <el-link
                    class="app-mr10"
                    type="primary"
                    @click="copy(scope.row)"
                    >复制账密</el-link
                  >
                  <el-link
                    class="app-mr10"
                    type="primary"
                    @click="accountEdit(scope.row)"
                    >修改账密</el-link
                  >
                  </div>
                  <div>
                    <el-link
                    class="app-mr10"
                    type="primary"
                    @click="addPreOrder(scope.row)"
                    >添加预订单</el-link
                  >
                  <el-link
                    class="app-mr10"
                    type="primary"
                    @click="listPreOrder(scope.row)"
                    >
                    预订单列表
                    <el-badge v-if="scope.row.pre_orders.length" :value="scope.row.pre_orders.length" type="info"></el-badge>
                  </el-link
                  >
                  </div>
                  <div>
                    <el-link
                    class="app-mr10"
                    type="primary"
                    @click="edit(scope.row)"
                    >编辑</el-link
                  >
                  <el-link type="danger" @click="del(scope)">删除</el-link>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination app-pr10 app-pt10 app-pb10" flex="main:right">
          <el-pagination
            layout="total, prev, pager, next, jumper"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            @current-change="handleChangePage"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 编辑账密 -->
    <el-drawer
      title="编辑账密"
      :visible.sync="showAccountEdit"
      @close="resetForm('accountEdit')"
      size="50%"
      destroy-on-close
      :wrapper-closable="false"
    >
      <div class="drawer-wrapper">
        <div class="drawer-wrapper-content">
          <el-form
            :model="formDataAccountEdit"
            :rules="rulesAccountEdit"
            ref="accountEdit"
            @keyup.enter.native="handleEditAccount('accountEdit')"
          >
            <el-form-item label="账号名称" prop="account_name">
              <el-input
                v-model="formDataAccountEdit.account_name"
                disabled
                placeholder="请输入账号名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="账号密码" prop="password">
              <div flex class="app-w100">
                <div flex-box="10">
                  <el-input
                    v-model="formDataAccountEdit.password"
                    placeholder="请输入账号密码"
                    clearable
                  ></el-input>
                </div>
                <div flex-box="0">
                  <el-button
                    size="small"
                    style="margin-left: 5px"
                    @click="genRandomPass"
                    >随机密码</el-button
                  >
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-wrapper-footer">
          <el-button type="primary" @click="handleEditAccount('accountEdit')"
            >提交</el-button
          >
          <el-button @click="showAccountEdit = false">取消</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 新增游戏 -->
    <el-drawer
      title="新增"
      :visible.sync="showAdd"
      @close="resetForm('add')"
      size="50%"
      destroy-on-close
      :wrapper-closable="false"
    >
      <div class="drawer-wrapper">
        <div class="drawer-wrapper-content">
          <el-form
            :model="formDataAdd"
            :rules="rules"
            ref="add"
            @keyup.enter.native="submitForm('add')"
          >
            <el-form-item label="游戏名称" prop="name">
              <el-input
                v-model="formDataAdd.name"
                placeholder="请输入游戏名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-wrapper-footer">
          <el-button type="primary" @click="submitForm('add')">提交</el-button>
          <el-button @click="showAdd = false">取消</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 编辑位置 -->
    <el-drawer
      title="编辑"
      :visible.sync="showEdit"
      @close="resetForm('edit')"
      size="50%"
      destroy-on-close
      :wrapper-closable="false"
    >
      <div class="drawer-wrapper">
        <div class="drawer-wrapper-content">
          <el-form
            :model="formDataEdit"
            :rules="rules"
            ref="edit"
            @keyup.enter.native="submitForm('edit')"
          >
            <el-form-item label="游戏名称" prop="game_name">
              <el-input
                disabled
                v-model="formDataEdit.game_name"
                placeholder="请输入游戏名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="账号名称" prop="account_name">
              <el-input
                disabled
                v-model="formDataEdit.account_name"
                placeholder="请输入账号名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="位置名称" prop="name">
              <el-input
                v-model="formDataEdit.name"
                placeholder="请输入位置名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="note">
              <el-input
                v-model="formDataEdit.note"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-wrapper-footer">
          <el-button type="primary" @click="submitForm('edit')">提交</el-button>
          <el-button @click="showEdit = false">取消</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 新增位置 -->
    <el-drawer
      title="添加位置"
      :visible.sync="showAddPosition"
      @close="resetForm('addPosition')"
      size="50%"
      destroy-on-close
      :wrapper-closable="false"
    >
      <div class="drawer-wrapper">
        <div class="drawer-wrapper-content">
          <el-form
            :model="formDataAddPosition"
            :rules="rulesPosition"
            ref="addPosition"
            @keyup.enter.native="handleAddPosition"
          >
            <el-form-item label="游戏名称" prop="game_name">
              <el-input
                disabled
                v-model="formDataAddPosition.game_name"
                placeholder="请输入游戏名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="账号名称" prop="account_name">
              <el-input
                disabled
                v-model="formDataAddPosition.account_name"
                placeholder="请输入账号名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="位置名称" prop="name">
              <el-input
                v-model="formDataAddPosition.name"
                placeholder="请输入位置名称"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-wrapper-footer">
          <el-button type="primary" @click="handleAddPosition">提交</el-button>
          <el-button @click="showAddPosition = false">取消</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 设置状态 -->
    <el-drawer
      title="设置状态"
      :visible.sync="showEditStatus"
      @close="resetStatus()"
      size="50%"
      destroy-on-close
      :wrapper-closable="false"
    >
      <div class="drawer-wrapper">
        <div class="drawer-wrapper-content">
          <div class="current-position-info">
            {{ calcCurrentPositonText() }}
          </div>
          <el-form class="current-position-status">
            <el-radio-group v-model="formDataStatus.status">
              <el-radio :label="0">空闲</el-radio>
              <el-radio :label="1">有人</el-radio>
              <el-radio :label="2">待回收</el-radio>
              <el-radio :label="3">解除认证中</el-radio>
            </el-radio-group>
          </el-form>
          <el-form
            v-if="formDataStatus.status == 1"
            :model="formDataOrder"
            :rules="rulesOrder"
            ref="editStatus-1"
          >
            <el-form-item label="淘宝下单账号" prop="order_account">
              <el-input
                v-model="formDataOrder.order_account"
                placeholder="请输入淘宝下单账号"
                clearable
                @blur="checkVip"
              ></el-input>
              <div
                v-if="formDataOrder.order_account && vipInfo"
                style="
                  background-color: #eee;
                  padding-left: 5px;
                  font-size: 12px;
                "
                v-html="vipInfo"
              ></div>
            </el-form-item>
            <el-form-item label="淘宝订单号" prop="order_sn">
              <el-input
                v-model="formDataOrder.order_sn"
                placeholder="请输入淘宝订单号"
                clearable
                @blur="findPreOrder"
              ></el-input>
            </el-form-item>
            <el-form-item label="价格" prop="price">
              <el-input
                v-model="formDataOrder.price"
                placeholder="请输入价格"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="订单起止日期" prop="start_end_time">
              <el-date-picker
                style="width: 100% !important"
                v-model="formDataOrder.start_end_time"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注" prop="note">
              <el-input
                v-model="formDataOrder.note"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
          <el-form
            v-if="formDataStatus.status == 3"
            :model="formDataStatus"
            :rules="rulesStatus_3"
            ref="editStatus-3"
          >
            <el-form-item prop="auth_end_time" label="解除认证截止日期">
              <el-date-picker
                style="width: 100% !important"
                type="date"
                placeholder="请选择解除认证截止日期"
                value-format="yyyy-MM-dd"
                v-model="formDataStatus.auth_end_time"
              ></el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-wrapper-footer">
          <el-button type="primary" @click="handleEditStatus">提交</el-button>
          <el-button @click="showEditStatus = false">取消</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 添加预订单 -->
    <el-drawer
      title="添加预订单"
      :visible.sync="showAddPreOrder"
      @close="resetFormDataPreOrder()"
      size="50%"
      destroy-on-close
      :wrapper-closable="false"
    >
      <div class="drawer-wrapper">
        <div class="drawer-wrapper-content">
          <div class="current-position-info">
            {{ calcCurrentPositonText() }}
          </div>
          <el-form :model="formDataOrder" :rules="rulesOrder" ref="addPreOrder">
            <el-form-item label="淘宝下单账号" prop="order_account">
              <el-input
                v-model="formDataOrder.order_account"
                placeholder="请输入淘宝下单账号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="淘宝订单号" prop="order_sn">
              <el-input
                v-model="formDataOrder.order_sn"
                placeholder="请输入淘宝订单号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="价格" prop="price">
              <el-input
                v-model="formDataOrder.price"
                placeholder="请输入价格"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="订单起止日期" prop="start_end_time">
              <el-date-picker
                style="width: 100% !important"
                v-model="formDataOrder.start_end_time"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注" prop="note">
              <el-input
                v-model="formDataOrder.note"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="drawer-wrapper-footer">
          <el-button type="primary" @click="handleAddPreOrder">提交</el-button>
          <el-button @click="showAddPreOrder = false">取消</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
// import {mapMutations, mapState, mapGetters, mapActions} from 'vuex';
import { mapActions, mapMutations, mapState } from "vuex";
import PanelDrawer from "../components/PanelDrawer.vue";

export default {
  name: "position-list",
  components: {
    PanelDrawer,
  },
  data() {
    return {
      vipInfo: null,
      currentPositon: null,
      formDataOrder: {
        pre_order_id: 0,
        game_id: 0,
        game_name: "",
        account_id: 0,
        account_name: "",
        position_id: 0,
        position_name: "",
        price: "",
        order_account: "",
        order_sn: "",
        start_time: "",
        end_time: "",
        note: "",
        start_end_time: "",
      },
      formDataStatus: {
        id: 0,
        status: 0,
        order_sn: "",
        order_end_time: "",
        auth_end_time: "",
      },
      formDataAddPosition: {
        game_id: 0,
        game_name: "",
        account_id: 0,
        account_name: "",
        name: "",
      },
      formDataAdd: {
        name: "", // 游戏名称
      },
      formDataEdit: {
        id: 0,
        game_id: 0,
        game_name: "",
        account_id: 0,
        account_name: "",
        name: "",
        note: "",
      },
      formDataAccountEdit: {
        id: 0,
        account_name: "",
        password: "",
      },
      rulesAccountEdit: {
        password: [
          { required: true, message: "请输入账号密码", trigger: "blur" },
        ],
      },
      rules: {
        name: [{ required: true, message: "请输入位置名称", trigger: "blur" }],
      },
      rulesOrder: {
        order_account: [
          { required: true, message: "请输入淘宝下单账号", trigger: "blur" },
        ],
        order_sn: [
          { required: true, message: "请输入淘宝订单号", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入价格", trigger: "blur" },
          {
            required: true,
            type: "number",
            transform(v) {
              return +v;
            },
            message: "请输入正确的价格",
            trigger: "blur",
          },
        ],
        start_end_time: [
          { required: true, message: "请选择订单起止日期", trigger: "blur" },
        ],
      },
      rulesPosition: {
        name: [{ required: true, message: "请输入位置名称", trigger: "blur" }],
      },
      rulesStatus_1: {
        auth_end_time: [
          {
            required: true,
            message: "请选择解除认证截止日期",
            trigger: "blur",
          },
        ],
      },
      rulesStatus_3: {
        auth_end_time: [
          {
            required: true,
            message: "请选择解除认证截止日期",
            trigger: "blur",
          },
        ],
      },
      showDialogCloseTip: false,
      tableMaxHeight: 0,
      pagination: {
        page: 1,
        total: 0,
        pageSize: 15,
      },
      search: {
        account_id: this.$route.query.account_id
          ? this.$route.query.account_id
          : 0,
        name: "", // 位置名称
        status: "",
        game_name: "", // 游戏名称
        account_name: "", // 账号名称
      },
      record: {
        name: "",
        thumb: "",
        password: "",
        repassword: "",
        gender: 1,
        hobby: [],
        info: "",
        content: "",
        title: "", // 职级
        depart_belong: [],
        join_time: "",
        addr: [],
      },

      opt_depart_belong: [
        {
          label: "国防部",
          value: "gfb",
          children: [
            {
              label: "核心小组",
              value: "hxxz",
            },
            {
              label: "酱油组",
              value: "jyz",
            },
          ],
        },
        {
          label: "农业部",
          value: "nyb",
          children: [
            {
              label: "养猪办",
              value: "yzb",
            },
            {
              label: "种地办",
              value: "zdb",
            },
          ],
        },
      ],
      opt_addr: regionData,

      showDialogAdd: false,
      showDialogEdit: false,
      showDialogView: false,
      tableData: [],

      showAdd: false,
      showEdit: false,
      showAddPosition: false,
      showEditStatus: false,
      showAddPreOrder: false,
      showAccountEdit: false,
    };
  },
  computed: {
    ...mapState(["isLoading", "uploadBaseUrl", "activeMenu"]),
  },
  created() {
    this.getData();
  },
  mounted() {
    // this.record = {
    //     name: "丁禹",
    //     // thumb: "https://dss0.bdstatic.com/6Ox1bjeh1BF3odCf/it/u=4191265673,2281481057&fm=218&app=92&f=JPEG?w=121&h=75&s=C0B335D41CBB42940C08611A0300A060",
    //     thumb: "",
    //     password: "111111",
    //     repassword: "111111",
    //     gender: 1,
    //     hobby: ["西施", "貂蝉"],
    //     info: "简介",
    //     content: "<p>content here!</p>\n",
    //     title: "BOSS",
    //     depart_belong: ["gfb", "hxxz"],
    //     join_time: "2021-05-28",
    //     addr: ["510000", "510100", "510107"],
    // };
    // window.CKEDITOR.replace("content", {
    //   filebrowserImageUploadUrl: "http://test.my/upload.php",
    //   height: 500,
    // });
  },
  methods: {
    ...mapMutations(["disableLoading"]),
    handleEditAccount(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$request
            .post("account/update", this.formDataAccountEdit)
            .then((res) => {
              if (res.code != 0) {
                this.mToast(res.message, "error");
                return;
              }

              this.mToast("操作成功", "success");
              this.showAccountEdit = false;
            });
        }
      });
    },
    accountEdit(record) {
      this.formDataAccountEdit.id = record.account_id;
      this.formDataAccountEdit.account_name = record.account_name;
      this.formDataAccountEdit.password = "";
      this.showAccountEdit = true;
    },
    genRandomPass() {
      let password = this.$utils.genRandomPass({
        exclude: "iIl10oO",
        useSpecial: false,
      });
      if (password) this.formDataAccountEdit.password = password;
    },
    copy(row) {
      this.$request.post("account/one", { id: row.account_id }).then((res) => {
        if (res.code != 0) {
          this.mToast(res.message, "error");
          return;
        }

        let text = "账号：" + res.data.name + " 密码：" + res.data.password;
        this.$utils.copy(text, () => {
          this.mToast("复制成功", "success");
        });
      });
    },
    findPreOrder() {
      if (!this.formDataOrder.order_sn) {
        return;
      }

      this.$request
        .post("order-pre/one", {
          order_sn: this.formDataOrder.order_sn,
        })
        .then((res) => {
          if (res.code == 0) {
            this.mConfirm("该订单号存在预订单，需要导入吗?", () => {
              this.formDataOrder.pre_order_id = res.data.id;
              this.formDataOrder.order_account = res.data.order_account;
              this.formDataOrder.order_sn = res.data.order_sn;
              this.formDataOrder.price = res.data.price;
              this.formDataOrder.start_end_time = [
                this.$utils.getDate(res.data.start_time * 1000),
                this.$utils.getDate(res.data.end_time * 1000),
              ];
              this.formDataOrder.note = res.data.note;
            });
          }
        });
    },
    checkVip() {
      if (!this.formDataOrder.order_account) {
        return;
      }

      this.$request
        .post("vip/check-vip", {
          order_account: this.formDataOrder.order_account,
        })
        .then((res) => {
          if (res.code != 0) {
            this.mToast(res.message, "error");
            return;
          }

          this.vipInfo = res.message;

          // this.$alert(res.message, "温馨提示", {
          //   confirmButtonText: "确定",
          //   dangerouslyUseHTMLString: true,
          // });
        });
    },
    isOrderExpire(expireTime) {
      let nowTime = new Date().getTime();
      return nowTime - expireTime * 1000 > 24 * 3600 * 1000;
    },
    resetStatus() {
      this.currentPositon = null;

      this.formDataStatus.id = 0;
      this.formDataStatus.status = 0;
      this.formDataStatus.order_sn = "";
      this.formDataStatus.order_end_time = "";
      this.formDataStatus.auth_end_time = "";

      this.resetFormDataOrder();
    },
    resetFormDataPreOrder() {
      this.currentPositon = null;
      this.resetFormDataOrder();
    },
    resetFormDataOrder() {
      this.formDataOrder.pre_order_id = 0;
      this.formDataOrder.game_id = 0;
      this.formDataOrder.game_name = "";
      this.formDataOrder.account_id = 0;
      this.formDataOrder.account_name = "";
      this.formDataOrder.position_id = 0;
      this.formDataOrder.position_name = "";
      this.formDataOrder.price = "";
      this.formDataOrder.order_account = "";
      this.formDataOrder.order_sn = "";
      this.formDataOrder.start_time = "";
      this.formDataOrder.end_time = "";
      this.formDataOrder.note = "";
      this.formDataOrder.start_end_time = "";
      this.vipInfo = null;
    },
    calcCurrentPositonText() {
      if (this.currentPositon) {
        return (
          this.currentPositon.game_name +
          " → " +
          this.currentPositon.account_name +
          " → " +
          this.currentPositon.name
        );
      }

      return "";
    },
    handleEditStatus() {
      if (this.formDataStatus.status == this.currentPositon.status) {
        this.mToast("状态未更改，提交失败！", "error");
        return;
      }

      if (this.formDataStatus.status == 1) {
        this.$refs["editStatus-1"].validate((valid) => {
          if (valid) {
            // 先下订单
            this.formDataOrder.start_time =
              this.formDataOrder.start_end_time[0];
            this.formDataOrder.end_time = this.formDataOrder.start_end_time[1];
            this.$request.post("order/add", this.formDataOrder).then((res) => {
              if (res.code != 0) {
                this.mToast(res.message, "error");
                return;
              }

              this.formDataStatus.order_sn = this.formDataOrder.order_sn;
              this.formDataStatus.order_end_time = this.formDataOrder.end_time;
              this.submitEditStatus();
            });
          }
        });
      } else if (this.formDataStatus.status == 3) {
        this.$refs["editStatus-3"].validate((valid) => {
          if (valid) {
            this.submitEditStatus();
          }
        });
      } else {
        this.submitEditStatus();
      }
    },
    submitEditStatus() {
      this.$request.post("position/status", this.formDataStatus).then((res) => {
        if (res.code != 0) {
          this.mToast(res.message, "error");
          return;
        }

        this.mToast("操作成功", "success");
        this.showEditStatus = false;
        this.resetStatus();
        this.getData();
      });
    },
    addPreOrder(row) {
      this.currentPositon = row;

      this.formDataOrder.game_id = row.game_id;
      this.formDataOrder.game_name = row.game_name;
      this.formDataOrder.account_id = row.account_id;
      this.formDataOrder.account_name = row.account_name;
      this.formDataOrder.position_id = row.id;
      this.formDataOrder.position_name = row.name;

      this.showAddPreOrder = true;
    },
    handleAddPreOrder() {
      this.$refs["addPreOrder"].validate((valid) => {
        if (valid) {
          this.formDataOrder.start_time = this.formDataOrder.start_end_time[0];
          this.formDataOrder.end_time = this.formDataOrder.start_end_time[1];
          this.$request
            .post("order-pre/add", this.formDataOrder)
            .then((res) => {
              if (res.code != 0) {
                this.mToast(res.message, "error");
                return;
              }

              this.mToast("操作成功", "success");
              this.showAddPreOrder = false;
              this.resetFormDataPreOrder();
              this.getData();
            });
        }
      });
    },
    editStatus(row) {
      this.currentPositon = row;

      this.formDataStatus.id = row.id;
      this.formDataStatus.status = row.status;

      this.formDataOrder.game_id = row.game_id;
      this.formDataOrder.game_name = row.game_name;
      this.formDataOrder.account_id = row.account_id;
      this.formDataOrder.account_name = row.account_name;
      this.formDataOrder.position_id = row.id;
      this.formDataOrder.position_name = row.name;

      this.showEditStatus = true;
    },
    calcStatus(row) {
      switch (row.status) {
        case 0:
          return "空闲";
          break;

          return "有人";
      }
    },
    listPreOrder(record) {
      this.$router.push({
        path: "/order-pre-list-by-id",
        query: {
          position_id: record.id,
        },
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if ("add" == formName) {
            // 新增
            this.$request.post("position/add", this.formDataAdd).then((res) => {
              if (res.code != 0) {
                this.mToast(res.message, "error");
                return;
              }

              this.mToast("操作成功", "success");
              this.showAdd = false;
              this.getData();
            });
          } else {
            // 编辑
            this.$request
              .post("position/update", this.formDataEdit)
              .then((res) => {
                if (res.code != 0) {
                  this.mToast(res.message, "error");
                  return;
                }

                this.mToast("操作成功", "success");
                this.showEdit = false;
                this.getData();
              });
          }
        }
      });
    },
    handleAddPosition() {
      this.$refs["addPosition"].validate((valid) => {
        if (valid) {
          // 新增
          this.$request
            .post("position/add", this.formDataAddPosition)
            .then((res) => {
              if (res.code != 0) {
                this.mToast(res.message, "error");
                return;
              }

              this.mToast("操作成功", "success");
              this.showAddPosition = false;
              this.getData();
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.getData();
    },
    handleSearch() {
      this.getData();
    },
    onDialogClosed() {
      if (this.showDialogCloseTip) {
        this.mToast("操作成功", "success");
        this.disableLoading();
        this.getData();
        this.showDialogCloseTip = false;
      }
    },
    initRecord() {
      this.record = {
        name: "",
        thumb: "",
        password: "",
        repassword: "",
        gender: 1,
        hobby: [],
        info: "",
        content: "",
        title: "", // 职级
        depart_belong: [],
        join_time: "",
        addr: [],
      };
    },
    formatRecord(record) {
      this.record = Object.assign({}, this.record, record);
      this.record.addr = this.record.addr.split(",");
      this.record.depart_belong = this.record.depart_belong.split(",");
      this.record.hobby = this.record.hobby.split(",");
    },
    handleChangePage(page) {
      console.log("page", page);
      this.pagination.page = page;
      this.getData();
    },
    getData() {
      let params = { page: this.pagination.page, ...this.search };
      // this.disableLoading();
      this.$request.get("position/index", { params: params }).then(
        (res) => {
          this.tableMaxHeight = this.calcTableHeight();
          this.tableData = res.data.items;
          this.pagination.total = res.data.pagination.totalCount;
          //   this.pagination.pageSize = res._meta.perPage;
        },
        (err) => console.log(err)
      );
    },
    calcTableHeight() {
      return (
        document.getElementsByClassName("page-container")[0].clientHeight -
        document.getElementsByClassName("breadcrumb-container")[0]
          .clientHeight -
        document.getElementsByClassName("content-header")[0].clientHeight -
        document.getElementsByClassName("pagination")[0].clientHeight
      );
    },
    handleAvatarFail(err) {
      if (typeof err.message === "string") {
        err.message = JSON.parse(err.message);
      }
      this.mToast(err.message.message, "error");
    },
    handleAvatarSuccess(res) {
      this.record.thumb = res;
    },
    beforeAvatarUpload(file) {
      // 可限制图片类型及大小
      // const isJPG = file.type === 'image/jpeg';
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      // this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // return isJPG && isLt2M;
    },
    initRichText(id) {
      if (window.CKEDITOR.instances[id]) {
        window.CKEDITOR.instances[id].destroy();
      }
      this.$nextTick(function () {
        window.CKEDITOR.replace(id, {
          filebrowserImageUploadUrl:
            this.uploadBaseUrl + "/upload/ck-upload-img",
          height: 500,
        });
        // 滚动至dialog顶部
        document.getElementsByClassName("el-dialog__wrapper")[0].scrollTop = 0; // add
        document.getElementsByClassName("el-dialog__wrapper")[1].scrollTop = 0; // edit
      });
    },
    getRichTextValue(id) {
      if (window.CKEDITOR.instances[id]) {
        return window.CKEDITOR.instances[id].getData();
      }

      return null;
    },
    submitAdd() {
      // 验证
      let content = this.getRichTextValue("content_add");
      if (!content) {
        this.mToast("内容不能为空哦", "error");
        return false;
      } else {
        this.record.content = content;
      }

      this.$utils.logJson(this.record);
      this.disableLoading();
      this.$request.post("demo", this.record).then(
        () => {
          this.$refs["form-add"].resetFields();
          this.showDialogAdd = false;
          this.showDialogCloseTip = true;
        },
        (err) => {
          this.mToast(err.data[0]["message"], "error");
        }
      );
    },
    submitEdit() {
      // 验证
      let content = this.getRichTextValue("content_edit");
      if (!content) {
        this.mToast("内容不能为空哦", "error");
        return false;
      } else {
        this.record.content = content;
      }

      this.disableLoading();
      this.$request.put("demo/" + this.record.id, this.record).then(
        () => {
          this.$refs["form-edit"].resetFields();
          this.showDialogEdit = false;
          this.showDialogCloseTip = true;
        },
        (err) => {
          this.mToast(err.data[0]["message"], "error");
        }
      );
    },
    add() {
      this.showAdd = true;
    },
    addPosition(record) {
      this.formDataAddPosition.game_id = record.game_id;
      this.formDataAddPosition.game_name = record.game_name;
      this.formDataAddPosition.account_id = record.id;
      this.formDataAddPosition.account_name = record.name;
      this.showAddPosition = true;
    },
    view(record) {
      this.formatRecord(record);
      this.showDialogView = true;
      this.initRichText("content_view");
    },
    edit(record) {
      this.formDataEdit.id = record.id;
      this.formDataEdit.game_id = record.game_id;
      this.formDataEdit.game_name = record.game_name;
      this.formDataEdit.account_id = record.account_id;
      this.formDataEdit.account_name = record.account_name;
      this.formDataEdit.name = record.name;
      this.formDataEdit.note = record.note;
      this.showEdit = true;
    },
    del(scope) {
      this.mConfirm("确定删除?", () => {
        // 进行登录
        this.$request
          .post("position/delete", { id: scope.row.id })
          .then((res) => {
            if (res.code != 0) {
              this.mToast(res.message, "error");
              return;
            }

            this.mToast("操作成功", "success");
            this.getData();
          });
      });
    },
  },
};
</script>

<style scoped lang="less">
.page-container {
  height: calc(100vh - @height-header);
  .drawer-wrapper {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    .current-position-info {
      margin-bottom: 10px;
      color: #72767b;
      font-style: italic;
    }
    .current-position-status {
      margin-top: 23px;
      margin-bottom: 10px;
    }
    .drawer-wrapper-content {
      flex: 1;
    }
    .drawer-wrapper-footer {
      text-align: right;
      padding-bottom: 10px;
    }
  }
  .form-mask {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 999;
  }
  .breadcrumb-container {
    padding: 20px 10px;
  }
  .content {
    padding: 0 10px 20px 10px;
  }
  .avatar-uploader {
    line-height: 0;
    .thumb-wrapper {
      img {
        left: 0;
        top: 0;
      }
      .thumb-mask {
        display: none;
      }
      &:hover {
        .thumb-mask {
          display: flex;
        }
      }
    }
    .avatar-uploader-icon {
      width: 75px;
      height: 75px;
      line-height: 75px;
      text-align: center;
      border: dashed 1px @border-color-1;
      color: #999;
      &:hover {
        border-color: @color-primary;
        color: @color-primary;
      }
    }
  }
}
</style>